import React, { useState } from "react";
import { updateLiveOnlinePay } from "../../../../../Network/OnlinePayApi";
import { useDispatch, useSelector } from "react-redux";

const MpesaTesting = ({ type, mpesaOnline }) => {
	//redux dispatch
	const dispatch = useDispatch();

	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const User = useSelector((state) => state.auth.user);

	const [Key1, setKey1] = useState("");
	const [Key2, setKey2] = useState("");
	const [Identity, setIdentity] = useState("");
	const [Loading, setLoading] = useState(false);
	const [Live, setLive] = useState("live-test");

	const SubmitData = async (e) => {
		e.preventDefault();
		setLoading(true);
		let linker = Date.now();
		await updateLiveOnlinePay(
			{
				type:
					type === "sandbox-test"
						? type
						: Live === "live-test"
						? "live-test"
						: mpesaOnline
						? "live-edit"
						: "live-add",
				id: mpesaOnline.id,
				key1: Key1,
				key2: Key2,
				identity: Identity,
				linker,
				instLinker: ActiveCred.instLinker,
				credLinker: ActiveCred.linker,
				live: 1,
				status: 0,
				trace: linker,
				deleted: 0,
			},
			User.token,
			dispatch
		);
		setLoading(false);
	};

	return (
		<div className="row justify-content-center">
			<div className="col-md-10 col-lg-8">
				<div className="card">
					<form className="m-2" onSubmit={SubmitData}>
						<div
							className={`card-header ${
								type === "live-test" ? "bg-success" : null
							} `}
						>
							<p className="card-title ">
								{type === "sandbox-test" ? (
									"Sandbox Testing"
								) : (
									<>
										<span className="text-light text-decoration-underline h5">
											Live Testing
										</span>
										<br />
										<ul>
											<li className="text-light ">
												On Confirm We Only Connect Your Paybill Notifications To
												The System{" "}
											</li>
											<li className="text-light ">
												If At Any Point You Wish To Stop It Or Change It, You
												Can Go To Your Daraja Portal, Self Service And Delete
												Urls
											</li>
											<li className="text-light text-decoration-underline">
												{" "}
												<strong>
													<span className="h5">NB : </span>
													<span className="h6">
														We Do Not Save These Keys Or Paybill Or Receive
														Funds On Your Behalf
													</span>
												</strong>
											</li>
										</ul>
									</>
								)}
							</p>
						</div>
						<div className="card-body">
							<div className="form-group">
								<label className="label">
									Business Shortcode{" "}
									{"{ Received After Going Live At The Daraja App}"}:
								</label>
								<input
									type="number"
									required
									value={Identity}
									onChange={(e) => setIdentity(e.target.value)}
									placeholder="Enter Business Shortcode"
									className="form-control rounded"
								/>
							</div>{" "}
							<hr />
							<div className="form-group">
								<label className="label">Consumer Key :</label>
								<input
									required
									value={Key1}
									onChange={(e) => setKey1(e.target.value)}
									placeholder="Enter Consumer Key"
									className="form-control rounded"
								/>
							</div>
							<hr />
							<div className="form-group">
								<label className="label">Consumer Secret :</label>
								<input
									required
									value={Key2}
									onChange={(e) => setKey2(e.target.value)}
									placeholder="Enter Consumer Secret"
									className="form-control rounded"
								/>
							</div>
						</div>
						<div className="card-footer d-flex justify-content-around">
							{Loading ? (
								<span className="spinner-border text-primary"></span>
							) : type === "sandbox-test" ? (
								<button className="btn btn-primary rounded-pill" type="submit">
									SandBox Test
								</button>
							) : (
								<>
									<button
										className="btn btn-success rounded-pill"
										type="submit"
										onClick={() => {
											setLive("live-save");
										}}
									>
										Connect
									</button>
								</>
							)}
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default MpesaTesting;
