import React, { useState, useEffect } from "react";
import ProductCrud from "./ProductCrud";
import { useDispatch, useSelector } from "react-redux";
import NoProduct from "./NoProduct";
import ProductDisp from "./ProductDisp";
import { FetchProducts } from "../../../Network/ProductApi";
import { PlainList } from "flatlist-react";
import ProductEntry from "./ProductEntry";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Products = () => {
	useEffect(() => {
		FetchProducts(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [Product, setProduct] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");
	const [Cat, setCat] = useState("all");
	const [Entry, setEntry] = useState({});
	const [EntryModal, setEntryModal] = useState(false);
	const [EntryType, setEntryType] = useState(false);

	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Products = useSelector((state) => state.product.products);
	const Entries = useSelector((state) => state.entry.entries);

	const Cats = useSelector((state) => state.shopCat.shopCats).filter(
		(cat) => parseInt(cat.deleted) === 0
	);

	const SearchProduct = Products.filter(
		(product) =>
			(product.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				product.details.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(product.deleted) === 0 &&
			(parseInt(product.catLinker) === parseInt(Cat) || Cat === "all")
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<div className="d-flex justify-content-around mt-2">
				<p className="text-center h6 text-primary text-capitalize">
					{SearchProduct.length} Products
				</p>{" "}
				<button
					className="btn btn-sm btn-outline-secondary"
					onClick={() => {
						setType("add");
						setShowModal(true);
						setProduct({
							linker: Date.now(),
							trace: Date.now(),
							name: "",
							details: "",
							amount: "",
							catLinker: "",
							deleted: "0",
						});
					}}
				>
					Add Product
				</button>
			</div>

			<table className="table table-sm" id={"pham-products"}>
				<thead className="thead-dark">
					<tr>
						<th scope="column">
							<input
								type={"text"}
								placeholder={`Search Product`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<th>Details</th>
						<th scope="column">S.P.</th>
						<th scope="column">QTY</th>
						<th scope="column">
							<select
								className="form-control rounded"
								value={Cat}
								onChange={(e) => setCat(e.target.value)}
							>
								<option value={"all"}>All Categories</option>
								{Cats.map((cat, index) => (
									<option key={index} value={cat.linker}>
										{cat.name}
									</option>
								))}
							</select>
						</th>
					</tr>
				</thead>
				{SearchProduct.length > 0 ? (
					<tbody>
						<PlainList
							list={SearchProduct}
							renderOnScroll
							renderItem={(product) => (
								<ProductDisp
									setShowModal={setShowModal}
									ShowModal={ShowModal}
									product={product}
									setProduct={setProduct}
									type={Type}
									setType={setType}
									setEntryType={setEntryType}
									key={product.linker}
									Cats={Cats}
									setEntry={setEntry}
									setEntryModal={setEntryModal}
									Entries={Entries.filter(
										(entry) =>
											parseInt(entry.productLinker) ===
												parseInt(product.linker) &&
											parseInt(entry.deleted) === 0
									)}
								></ProductDisp>
							)}
						/>
					</tbody>
				) : (
					<NoProduct></NoProduct>
				)}
			</table>
			<GeneratePdf id={"pham-products"} name={"shop-products"}></GeneratePdf>
			<ProductCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				product={Product}
				setProduct={setProduct}
				type={Type}
				Cats={Cats}
			></ProductCrud>
			<ProductEntry
				setShowModal={setEntryModal}
				ShowModal={EntryModal}
				type={EntryType}
				entry={Entry}
				setEntry={setEntry}
				product={Product}
			></ProductEntry>
		</div>
	);
};

export default Products;
