import swal from "sweetalert";
import {
	addCred,
	fetchCreds,
	setActiveCred,
	updateCred,
} from "../reducers/CredSlice";
import { ApiUrl } from "./Urls";

export const RegisterLiveCred = async (staff, link, token, dispatch) => {
	return await fetch(`${ApiUrl}/${link}`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...staff,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateCred(res.cred));
				if (link === "staff/super") {
					dispatch(setActiveCred(res.cred));
				}
			} else {
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchStaffs = async (
	instLinker,
	token,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveCreds(token, instLinker, online, dispatch, "staff/get");
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateCred(payload));
	}
};

export const updateLiveCred = (staff, link, token, dispatch) => {
	return fetch(`${ApiUrl}/${link}`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...staff,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateCred(res.cred));
			} else {
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch((err) => {
			swal({
				title: "Failed to connect",
				icon: "warning",
				timer: 3000,
			});
			return { status: "error" };
		});
};

export const FetchLiveCreds = (token, instLinker, online, dispatch, link) => {
	fetch(`${ApiUrl}/${link}`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchCreds(res.creds));
			}
		})
		.catch(() => {});
};

export const FetchUserLiveCreds = (token) => {
	return fetch(`${ApiUrl}/auth/creds`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				return res;
			} else {
				return 0;
			}
		})
		.catch((err) => {
			return { status: "error" };
		});
};
