import swal from "sweetalert";
import {
	addPayment,
	fetchPayments,
	updatePayment,
} from "../reducers/PaymentSlice";
import { ApiUrl } from "./Urls";

export const FetchPayments = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLivePayments(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updatePayment(payload));
	}
};

export const addLivePayment = (payment, instLinker, token, dispatch) => {
	return fetch(`${ApiUrl}/payment/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payment,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updatePayment(res.payment));
			} else {
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLivePayment = (payment, token, dispatch) => {
	return fetch(`${ApiUrl}/payment/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...payment,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updatePayment(res.payment));
			} else {
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLivePayments = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/payment/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchPayments(res.payments));
			}
		})
		.catch(() => {});
};
