import React, { useState, useEffect } from "react";
import PaymentCrud from "./PaymentCrud";
import { useDispatch, useSelector } from "react-redux";
import NoPayment from "./NoPayment";
import PaymentDisp from "./PaymentDisp";
import { FetchPayments } from "../../../Network/PaymentApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Payments = () => {
	useEffect(() => {
		FetchPayments(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [Payment, setPayment] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");
	const [By, setBy] = useState("all");
	const [Day, setDay] = useState("all");
	const [Month, setMonth] = useState("all");
	const [Year, setYear] = useState("all");
	const [Mode, setMode] = useState("all");

	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const Payments = useSelector((state) => state.payment.payments);
	const Creds = useSelector((state) => state.cred.creds);
	const Modes = useSelector((state) => state.mode.modes).filter(
		(mode) => parseInt(mode.deleted) === 0
	);

	const FindUser = (linker) => {
		let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

		return cred || { email: "unknown" };
	};

	const SearchPayment = Payments.filter(
		(payment) =>
			(payment.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				payment.details.toLowerCase().search(Search.toLowerCase()) > -1 ||
				payment.code.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(payment.deleted) === 0 &&
			(parseInt(payment.mode) === parseInt(Mode) || Mode === "all") &&
			(parseInt(
				new Date(parseInt(payment.linker)).toLocaleDateString().split("/")[2]
			) === parseInt(Year) ||
				Year === "all") &&
			(parseInt(
				new Date(parseInt(payment.linker)).toLocaleDateString().split("/")[0]
			) === parseInt(Month) ||
				Year === "all" ||
				Month === "all") &&
			(parseInt(
				new Date(parseInt(payment.linker)).toLocaleDateString().split("/")[1]
			) === parseInt(Day) ||
				Year === "all" ||
				Month === "all" ||
				Day == "all") &&
			(parseInt(payment.credLinker) === parseInt(By) || By === "all")
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<div id="payments">
				<p className="text-center h6 text-primary text-capitalize">
					{Payments.length} Payments
				</p>
				<table className="table table-sm">
					<thead className="thead-dark">
						<tr>
							<th scope="colum">Mode</th>
							<th scope="column">
								<input
									type={"text"}
									placeholder={`Search Payment`}
									value={Search}
									onChange={(e) => setSearch(e.target.value)}
									className="rounded form-control"
								/>
							</th>
							<th scope="column">Amount</th>

							<th scope="column">By</th>
							<th scope="column">Date</th>
						</tr>
						<tr>
							<th scope="column">
								<select
									className="form-control rounded"
									value={Mode}
									onChange={(e) => setMode(e.target.value)}
								>
									<option value={"all"}>All Modes</option>
									{[...Modes].map((mode, index) => (
										<option key={index} value={mode.linker}>
											{mode.name}
										</option>
									))}
								</select>
							</th>
							<th scope="column">Details</th>
							<th>{SearchPayment.reduce((a, b) => +a + +b.amount, 0)}</th>
							<th>
								<select
									className="form-control rounded"
									value={By}
									onChange={(e) => setBy(e.target.value)}
								>
									<option value={"all"}>All Staffs</option>
									<PlainList
										list={[
											...new Set(Payments.map((payment) => payment.credLinker)),
										]}
										renderOnScroll
										renderItem={(staff) => (
											<option value={staff} key={staff}>
												{FindUser(staff).email}
											</option>
										)}
									/>
								</select>
							</th>{" "}
							<th colSpan={2}>
								<div className="d-flex">
									<select
										className="form-control rounded"
										value={Year}
										onChange={(e) => setYear(e.target.value)}
									>
										<option value={"all"}>All Yrs</option>
										{[
											...new Set(
												Payments.map(
													(payment) =>
														new Date(parseInt(payment.linker))
															.toLocaleDateString()
															.split("/")[2]
												)
											),
										].map((yr) => (
											<option key={yr} value={yr}>
												{yr}
											</option>
										))}
									</select>
									{Year !== "all" ? (
										<select
											className="form-control rounded"
											value={Month}
											onChange={(e) => setMonth(e.target.value)}
										>
											<option value={"all"}>All Mths</option>
											{[
												...new Set(
													Payments.map(
														(payment) =>
															new Date(parseInt(payment.linker))
																.toLocaleDateString()
																.split("/")[0]
													)
												),
											].map((mth) => (
												<option key={mth} value={mth}>
													{mth}
												</option>
											))}
										</select>
									) : null}
									{Month !== "all" && Year !== "all" ? (
										<select
											className="form-control rounded"
											value={Day}
											onChange={(e) => setDay(e.target.value)}
										>
											<option value={"all"}>All Dates</option>
											{[
												...new Set(
													Payments.map(
														(payment) =>
															new Date(parseInt(payment.linker))
																.toLocaleDateString()
																.split("/")[1]
													)
												),
											].map((day) => (
												<option key={day} value={day}>
													{day}
												</option>
											))}
										</select>
									) : null}
								</div>
							</th>
						</tr>
					</thead>
					{SearchPayment.length > 0 ? (
						<tbody>
							<PlainList
								renderOnScroll
								list={SearchPayment}
								renderItem={(payment) => (
									<PaymentDisp
										setShowModal={setShowModal}
										ShowModal={ShowModal}
										payment={payment}
										setPayment={setPayment}
										type={Type}
										setType={setType}
										key={payment.linker}
									></PaymentDisp>
								)}
							/>
						</tbody>
					) : (
						<NoPayment></NoPayment>
					)}
				</table>
			</div>
			<GeneratePdf id={"payments"} name={"payments"}></GeneratePdf>
			<PaymentCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				payment={Payment}
				setPayment={setPayment}
				type={Type}
			></PaymentCrud>
		</div>
	);
};

export default Payments;
