import React from "react";
import { useParams } from "react-router-dom";

const NoShopCat = () => {
	const params = useParams();
	return (
		<tbody>
			<tr>
				<td>No Shop Categories</td>

				<td>
					These are various Shop categories offered in your health facility Shop
				</td>
			</tr>
		</tbody>
	);
};

export default NoShopCat;
