import React from "react";

const NoProducts = () => {
	return (
		<tbody>
			<tr>
				<th>No Products</th>

				<td>
					Here you can add products or medicines offered at the health facility
					shop
				</td>
			</tr>
		</tbody>
	);
};

export default NoProducts;
