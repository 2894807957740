import React, { useState, useEffect } from "react";
import ShopCatCrud from "./ShopCatCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoShopCat from "./NoShopCat";
import { FetchShopCats } from "../../../Network/ShopCatApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const ShopCats = () => {
	useEffect(() => {
		FetchShopCats(User.token, ActiveCred.instLinker, dispatch, online);
	}, []);

	const [ShowModal, setShowModal] = useState(false);
	const [ShopCat, setShopCat] = useState({ name: "" });
	const [Search, setSearch] = useState("");
	const [Type, setType] = useState("add");

	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const ShopCats = useSelector((state) => state.shopCat.shopCats);

	const SearchShopCat = ShopCats.filter(
		(shopCat) =>
			(shopCat.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "") &&
			parseInt(shopCat.deleted) === 0
	).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<p className="text-center h6 text-primary text-capitalize">
				{SearchShopCat.length} Shop Categories
			</p>
			<table className="table table-sm" id="pham-cat">
				<thead className="thead-dark">
					<tr>
						<th scope="column" colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Shop Categories`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<td>
							<button
								className="btn btn-sm btn-primary"
								onClick={() => {
									setShowModal(true);
									setType("add");
									setShopCat({ name: "", details: "" });
								}}
							>
								<PlusCircle /> Add Shop Category
							</button>
						</td>
					</tr>
				</thead>
				{SearchShopCat.length > 0 ? (
					<tbody>
						{SearchShopCat.map((shopCat, index) => (
							<tr key={index} className="item">
								<td>{shopCat.name}</td>
								<td>{shopCat.details}</td>
								<td>
									<button
										className="btn btn-xs btn-outline-info mx-1"
										onClick={() => {
											setShowModal(true);
											setType("edit");
											setShopCat({ ...shopCat });
										}}
									>
										{" "}
										<PencilSquare style={{ cursor: "pointer" }} />
									</button>
									<button
										className="btn btn-xs btn-outline-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setShopCat({ ...shopCat });
										}}
									>
										{" "}
										<Trash style={{ cursor: "pointer" }} />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<NoShopCat></NoShopCat>
				)}
			</table>
			<GeneratePdf id="pham-cat" name={"shop-categories"}></GeneratePdf>
			<ShopCatCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				shopCat={ShopCat}
				setShopCat={setShopCat}
				type={Type}
			></ShopCatCrud>
		</div>
	);
};

export default ShopCats;
