import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Dropdown } from "react-bootstrap";
import { PlainList } from "flatlist-react";
import { PatchMinus } from "react-bootstrap-icons";
import { addLivePayment } from "../../../Network/PaymentApi";
import { addLiveEntry } from "../../../Network/EntryApi";
import ReceiptModal from "./ReceiptModal";

const Pos = () => {
	const [Inputs, setInputs] = useState([]);
	const Products = useSelector((state) => state.product.products);
	const [Pay, setPay] = useState(false);
	const [Amount, setAmount] = useState(0);
	const [ModeLinker, setModeLinker] = useState("");
	const [Buyer, setBuyer] = useState("");
	const [Code, setCode] = useState("");
	const Modes = useSelector((state) => state.mode.modes).filter(
		(mode) => parseInt(mode.deleted) === 0
	);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const User = useSelector((state) => state.auth.user);
	const [Loading, setLoading] = useState(false);
	const [PrintReceipt, setPrintReceipt] = useState(false);

	//redux dispatch
	const dispatch = useDispatch();

	const SearchedProducts = (Search) =>
		Products.filter(
			(product) =>
				(product.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
					Search === "") &&
				parseInt(product.deleted) === 0
		);

	const HandleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setPrintReceipt(true);
		let linker = Date.now();
		let details = `${Buyer} `;
		for (let i = 0; i < Inputs.length; i++) {
			details +=
				`${Inputs[i].qty} ` +
				Inputs[i].product.name +
				`@ ${Inputs[i].product.amount} ` +
				`total ${Inputs[i].qty * Inputs[i].product.amount}; `;
		}

		let newPayment = {
			id: 0,
			linker,
			name: Buyer,
			details,
			mode: ModeLinker,
			amount: Inputs.reduce((a, b) => +a + (b.product.amount || 0) * b.qty, 0),
			code: Code,
			instLinker: ActiveCred.instLinker,
			credLinker: ActiveCred.linker,
			status: "Sale",
			trace: linker,
			live: 0,
			deleted: 0,
		};

		await addLivePayment(
			newPayment,
			ActiveCred.instLinker,
			User.token,
			dispatch
		);

		Inputs.map(async (input, index) => {
			linker += index;
			let newEntry = {
				id: 0,
				linker,
				instLinker: ActiveCred.instLinker,
				credLinker: ActiveCred.linker,
				trace: linker,
				live: 0,
				deleted: 0,
				type: "sale",
				details: `${Buyer} Shop Sale`,
				amount: input.qty * input.product.amount,
				quantity: input.qty,
				supplier: Buyer,
				productLinker: input.product.linker,
				trace: linker,
				status: 0,
			};

			await addLiveEntry(newEntry, ActiveCred.instLinker, User.token, dispatch);
		});
		setLoading(false);

		setPay(false);
	};

	return (
		<div>
			{" "}
			<div className="d-flex justify-content-around mt-2">
				<p className="text-center h6 text-primary text-capitalize">
					{Inputs.length} Products
				</p>{" "}
			</div>
			<Form onSubmit={HandleSubmit}>
				<table className="table table-sm">
					<thead className="thead-dark">
						<tr>
							<th>Product</th> <th>Cost</th> <th>Qty</th> <th>Total</th>{" "}
							<th></th>
						</tr>
					</thead>
					<tbody>
						{Inputs.map((input, index) => (
							<tr key={index}>
								<td>
									{" "}
									<Dropdown className="no-caret">
										<Dropdown.Toggle variant="transparent">
											<input
												className="form-control rounded mb-0"
												placeholder={`Search Product`}
												value={input.search}
												onChange={(e) => {
													Inputs[index].search = e.target.value;
													setInputs([...Inputs]);
												}}
											/>
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<PlainList
												list={SearchedProducts(input.search)}
												renderOnScroll
												renderItem={(product) => (
													<Dropdown.Item
														key={product.linker}
														onClick={() => {
															Inputs[index].search = product.name;
															Inputs[index].product = product;
															setInputs([...Inputs]);
														}}
													>
														{product.name}
													</Dropdown.Item>
												)}
											/>
										</Dropdown.Menu>
									</Dropdown>
								</td>
								<td>{input.product.amount || 0}</td>
								<td>
									<input
										className="form-control rounded mb-0"
										placeholder={`enter quantity`}
										value={input.qty}
										onChange={(e) => {
											Inputs[index].qty = e.target.value;
											setInputs([...Inputs]);
										}}
										type={"number"}
										onWheel={(e) => e.preventDefault()}
										min={1}
										required
									/>
								</td>
								<td>{(input.product.amount || 0) * input.qty}</td>
								<td>
									<PatchMinus
										onClick={() => {
											Inputs.splice(index, 1);
											setInputs([...Inputs]);
										}}
										style={{ cursor: "pointer" }}
										className="text-danger mx-2"
									/>
								</td>
							</tr>
						))}
						<tr>
							<th colSpan={2}>Sum</th>
							<th colSpan={3} className="d-flex justify-content-end">
								{Inputs.reduce(
									(a, b) => +a + (b.product.amount || 0) * b.qty,
									0
								)}
							</th>
						</tr>
					</tbody>
				</table>
				{Pay === true ? (
					<>
						<div className="row d-flex justify-content-center">
							<div className="col-12 col-md-6">
								<div className="card p-2">
									{Modes.length > 0 ? (
										<div>
											<label>Payment Mode :</label>
											<select
												onChange={(e) => setModeLinker(e.target.value)}
												value={ModeLinker}
												required
												className="form-control rounded"
											>
												<option>Select Payment Mode</option>
												{Modes.map((mode) => (
													<option key={mode.linker} value={mode.linker}>
														{mode.name}
													</option>
												))}
											</select>
											<hr />
										</div>
									) : null}
									<div>
										<label>Amount Paid:</label>
										<input
											className="form-control rounded"
											placeholder="Enter amount paid"
											value={Amount}
											onChange={(e) => setAmount(e.target.value)}
											type={"number"}
											onWheel={(e) => e.preventDefault()}
											required
										/>
										<hr />
									</div>
									<div>
										<p>
											<strong>Bal :</strong>
											<em>
												{Amount -
													Inputs.reduce(
														(a, b) => +a + (b.product.amount || 0) * b.qty,
														0
													)}
											</em>
										</p>
										<hr />
									</div>

									<div>
										<label>Transaction Code:</label>
										<input
											className="form-control rounded"
											placeholder="Enter transaction code"
											value={Code}
											onChange={(e) => setCode(e.target.value)}
											required
										/>
										<hr />
									</div>
									<div>
										<label>Buyer Name:</label>
										<input
											className="form-control rounded"
											placeholder="Enter buyer name"
											value={Buyer}
											onChange={(e) => setBuyer(e.target.value)}
											required
										/>
										<hr />
									</div>
								</div>
							</div>
						</div>
					</>
				) : null}
				<div className="d-flex justify-content-center">
					{Inputs.length > 0 && Pay === false ? (
						<button
							className="btn btn-sm btn-outline-primary rounded mx-2"
							onClick={() => {
								setPay(true);
								setBuyer("");
								setAmount("");
								setCode("");
								setModeLinker("");
							}}
							type={"button"}
						>
							Pay
						</button>
					) : null}
					{Loading ? (
						<span className="spinner-border text-primary"></span>
					) : Inputs.length > 0 && Pay === true ? (
						<button
							className="btn btn-sm btn-outline-primary rounded mx-2"
							type={"submit"}
						>
							Save
						</button>
					) : null}
					<button
						className="btn btn-sm btn-outline-secondary rounded mx-2"
						onClick={() => {
							setInputs([
								...Inputs,
								{ linker: Date.now(), product: {}, search: "", qty: 1 },
							]);
							setAmount(Inputs.length < 1 ? 0 : Amount);
							setBuyer(Inputs.length < 1 ? "" : Buyer);
						}}
						type={"button"}
					>
						Add Product
					</button>
				</div>
			</Form>
			<ReceiptModal
				Amount={Amount}
				Products={Inputs}
				setPrintReceipt={setPrintReceipt}
				PrintReceipt={PrintReceipt}
				ResetInputs={() => setInputs([])}
			></ReceiptModal>
		</div>
	);
};

export default Pos;
