import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ArrowBarLeft } from "react-bootstrap-icons";
import Purchase from "./Purchase";

const WebPay = () => {
	const Navigate = useNavigate();
	const inst = useSelector((state) => state.inst.activeInst);
	const subs = useSelector((state) => state.sub.subs);
	return (
		<div>
			{" "}
			<div
				className=" row d-flex justify-content-center"
				style={{ marginTop: "40px" }}
			>
				<div className="col-md-6 d-flex justify-content-around">
					<h6 className="text-center">
						Get {inst.name}{" "}
						{subs.length > 0
							? "Maintenance And Update Code At 29$ only"
							: "Activation Code at 99$ only"}
					</h6>
				</div>
			</div>
			<div className="row d-flex justify-content-center">
				<div className="col-md-8 justify-content-center">
					{subs.length > 0 ? (
						<Purchase
							softwareId={parseInt(
								parseInt(
									parseInt(`${inst.linker}`.split("").reverse().join("")) / 1000
								)
							)}
							amount={"29.0"}
							from="web"
						></Purchase>
					) : (
						<Purchase
							softwareId={parseInt(
								parseInt(
									parseInt(`${inst.linker}`.split("").reverse().join("")) / 1000
								)
							)}
							amount={"99.0"}
							from="web"
						></Purchase>
					)}
				</div>
			</div>
			<div className=" row d-flex justify-content-center">
				<div className="col-md-6 d-flex justify-content-around">
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={() => Navigate(-1)}
					>
						<ArrowBarLeft></ArrowBarLeft> Go Back
					</button>
				</div>
			</div>
		</div>
	);
};

export default WebPay;
