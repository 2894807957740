import { createSlice } from "@reduxjs/toolkit";

export const ShopCatSlice = createSlice({
	name: "shopCat",
	initialState: {
		shopCats: [],
	},
	reducers: {
		fetchShopCats: (state, action) => {
			state.shopCats = action.payload;
		},
		addShopCat: (state, action) => {
			state.shopCats = [action.payload, ...state.shopCats];
		},
		updateShopCat: (state, action) => {
			let index = state.shopCats.findIndex(
				(shopCat) =>
					parseInt(shopCat.linker) === parseInt(action.payload.linker)
			);

			let prevShopCats = [...state.shopCats];

			prevShopCats.splice(
				index < 0 ? 1 : index,
				index < 0 ? 0 : 1,
				action.payload
			);

			state.shopCats = prevShopCats;
		},
		deleteShopCat: (state, action) => {
			let newShopCats = state.shopCats.filter(
				(shopCat) =>
					parseInt(shopCat.linker) !== parseInt(action.payload.linker)
			);
			state.shopCats = newShopCats;
		},
	},
});

export const { fetchShopCats, addShopCat, updateShopCat, deleteShopCat } =
	ShopCatSlice.actions;

export default ShopCatSlice.reducer;
