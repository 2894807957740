import { configureStore } from "@reduxjs/toolkit";
import { LoadState, SaveState } from "./Utils/LocalStorage";
import AuthSlice from "./reducers/AuthSlice";
import CredSlice from "./reducers/CredSlice";
import InstSlice from "./reducers/InstSlice";
import ModeSlice from "./reducers/ModeSlice";
import PaymentSlice from "./reducers/PaymentSlice";
import OnlinePaySlice from "./reducers/OnlinePaySlice";
import MpesaPaymentsSlice from "./reducers/MpesaPaymentsSlice";
import ExpenseCatSlice from "./reducers/ExpenseCatSlice";
import ExpenseSlice from "./reducers/ExpenseSlice";
import ShopCatSlice from "./reducers/ShopCatSlice";
import ProductSlice from "./reducers/ProductSlice";
import EntrySlice from "./reducers/EntrySlice";
import SubSlice from "./reducers/SubSlice";
import OnlineSlice from "./reducers/OnlineSlice";
import PwaSlice from "./reducers/PwaSlice";
import ActiveSlice from "./reducers/ActiveSlice";
import PayrollEntrySlice from "./reducers/PayrollEntrySlice";
import PayrollCatSlice from "./reducers/PayrollCatSlice";
import PayrollItemSlice from "./reducers/PayrollItemSlice";
import StaffPayItemSlice from "./reducers/StaffPayItemSlice";
import StaffPayEntrySlice from "./reducers/StaffPayEntrySlice";

const persistedState = LoadState();

const store = configureStore({
	reducer: {
		auth: AuthSlice,
		inst: InstSlice,
		cred: CredSlice,
		mode: ModeSlice,
		expenseCat: ExpenseCatSlice,
		expense: ExpenseSlice,
		payment: PaymentSlice,
		mpesaPayment: MpesaPaymentsSlice,
		onlinePay: OnlinePaySlice,
		shopCat: ShopCatSlice,
		product: ProductSlice,
		entry: EntrySlice,
		payrollEntry: PayrollEntrySlice,
		payrollCat: PayrollCatSlice,
		payrollItem: PayrollItemSlice,
		staffPayItem: StaffPayItemSlice,
		staffPayEntry: StaffPayEntrySlice,
		sub: SubSlice,
		online: OnlineSlice,
		pwa: PwaSlice,
		active: ActiveSlice,
	},
	preloadedState: persistedState,
});

store.subscribe(() => {
	SaveState(store.getState());
});

export default store;
